<template>
    <div v-if="row.item.lf_document">
        <span v-if="row.item.lf_document.url">
            <font-awesome-icon icon="save"></font-awesome-icon>&nbsp;
            <a target="_blank"
               @click.prevent="$root.openDocument(row.item.lf_document.name)"
               :href="$root.getDocumentUrl(row.item.lf_document.name)"
            >{{ row.item.lf_document.name }}</a>
        </span>
        <span v-else>{{ row.item.lf_document.name }}</span>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
    },
}
</script>